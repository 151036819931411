
.btn-primary {
  background: $primary;
  color: white !important;

  &:hover,
  &:focus,
  &:active {
    background-color: lighten( $primary, 5%) !important;
  }
}

.table-hover tbody tr:hover {
  //background: #FFF8F2 !important;
  background-color: transparentize(lighten($primary, 45%), 0.1) !important;
  cursor: pointer;
}

@import "bootstrap";

@font-face {
  font-family: 'Public Sans';
  src: url('/assets/fonts/PublicSans-Regular.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Public Sans Light';
  src: url('/assets/fonts/PublicSans-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Public Sans Thin';
  src: url('/assets/fonts/PublicSans-Thin.ttf') format('truetype');
}

html, body {
  font-family: "Public Sans Light", sans-serif;
  font-size: 12px;
  background: linear-gradient(#F3F2F2, #fff);
  background-size: 100vw 100vh;
  background-repeat: no-repeat;
  background-position: top left;
}

.pagination p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

h4 {
  text-transform: uppercase;
  font-size: 1.1rem;
}

h5 {
  text-transform: uppercase;
  color: #A19D9A;
  font-size: 0.8rem;
}

footer {
  position: absolute;
  bottom:0;
  text-align: center;
  font-size: 0.75rem;
  width: 100%;
  opacity: 0.8;
}

.full-height {
  min-height: 100vh;
  position: relative;
}

.flex-7 {
  flex: 7 1;
}

.page-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height:100vh;
  width: 100vw;
}

.errors {
  color:red;
}

#errorMessages {
  margin-top: 2vh;
}

.data-page .table {
  th {
    color:white;
    background-color: $primary;
    text-align: center;
  }

  td {
    text-align: center;
  }
}

.dashed-border-right {
  border-right: 2px dashed #0070c0;
  padding-right: 10px;
  margin-right: 10px;
}

.form-select-box {
  background-color: white;
  cursor: pointer;
  min-width: 180px;
  max-height: 200%;

  border:1px solid #ddd;

  & > div {
    padding:5px;
  }

  & > :first-child{
    flex-grow: 1;
  }

  & > :last-child{
    padding-left:10px;
    padding-right:10px;
  }

  &:hover {
    opacity: 0.6;
  }
}

.form-select-option {
  left: 0;
  background-color: white;
  border:1px solid #ddd;

  top : 100%;
  z-index : 10;
  width:100%;
  cursor: pointer;
  min-width: 180px;
  overflow: auto;
  max-height: 16vh;
  position: absolute;

  & > div {
    padding:0 5px;

    &:hover {
      background-color: #ddd;
    }
  }
}

.width-100p {
  width: 100%;
}

.font-small {
  font-size: 0.9rem;
}

.font-xsmall {
  font-size: 0.8rem;
}

.font-xxsmall {
  font-size: 0.7rem;
}

.font-large {
  font-size: 1.2rem;
}

.mw-25 {
  max-width: 25%;
}

.mw-35 {
  max-width: 35%;
}

.mw-400px {
  max-width: 400px;
}

.mw-500px {
  max-width: 500px;
}

.fb-250px {
  flex-basis: 250px;
  max-width: 250px;
}

.preview-plus {
  display: block;
  position: absolute;
  z-index: 0;
  min-width: 100px;
  min-height: 80px;
}

.preview {
  max-width: 110px;
  max-height: 80px;
}

.pointer {
  cursor: pointer;
}

.text-underline {
  text-decoration: underline;
}

.text-blue {
  color: blue;
}

.fa-filter.blue path{
  fill:rgb(74, 195, 195);
}

.no-wrap {
  white-space: nowrap;
}

.btn-fade-over {
  transition: 0.25s all;
  &:hover {
    opacity: 0.25;
  }
}

.invoice-duplicates {
  background-color:rgba(240, 212, 0, 0.5);
  font-weight: bolder;
}

.mock-link {
  color: $primary;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.form-field {
  position: relative;

  .form-actions-container {
    position: absolute;
    left:100%;
    background-color: white;
    z-index: 1;
  }

  .action-buttons {
    button {
      padding:0;
      transition: none !important;
    }
  }

  svg {
    height:25px;
    width:auto !important;
    margin-top: auto;
  }

  .action-buttons-sm {
    svg {
      height: 20px;
      margin-top: 5px;
    }
  }

  .form-control, .form-control-sm {
    border:0;
  }

  .field-unselected {
    border-width:0;
    padding:0;
    color: #212529;
    z-index:0;
  }

  textarea {
    border-width:1px !important;
  }

  .field-selected:focus{
    padding:0;
    z-index:10;
  }

  .form-control:disabled,
  .form-control[readonly] {
    background-color: transparent;
  }

  .search-results {
    position: absolute;
    top:95%;
    left:0;
    z-index: 10;
    /*width: 100%;
    font-size: 0.875rem;*/
    background-color: white;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;

    div {
      &:hover {
        background-color: #ddd;
      }
    }
  }
}

.modal {
  background-color: rgba(0, 0, 0, 0.3);
}

.rounded-box {
  background-color: white;
  border-radius: 6px;
  border:1px solid #ddd;
  padding:10px;
}

.d-flex {
  .rounded-box {
    flex:1;
    margin:10px;
  }
}

.monitor-page, .cashflow-page, .data-page {
  .btn-group {
    margin-right: 10px;

    button {
      font-size: 1.1rem;
      padding:0 20px;
      color:#555;
      font-weight: 600;
      border:1px solid #ddd;
    }
  }
}

.huge-number {
  color:$primary;
  font-size: 1.6vw;
  text-align: center;
  font-family: 'Public Sans Thin';
}

.graph-data-label {
  font-size: 0.9rem;
  opacity: 0.7;
  text-align: center;
}

.min-height-45 {
  min-height: 45vh;
}

.min-width-1000 {
  min-width: 1000px;
}
.min-height-70 {
  min-height: 70vh;
}

.border-radius-30 {
  border-radius: 30px;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  min-height: 100vh;
  width: 100vw;
  z-index: 2;
  top:0;
  left:0;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    padding:10px;
  }

  .overlay-content {
    height: calc(100vh - 40px);
    overflow: auto;
    width: 95vw;

    object {
      height: 100%;
    }
  }
}

.loading-container {
  width: 90vw;
  height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  color:$primary;

  svg {
    width: 100px !important;
    height:100px;
  }
}

.advanced-search-section {
  position: absolute;
  top:0;
  left:0;
  opacity:0;
  overflow: hidden;
  animation: fadeIn 0.2s linear both;
  animation-iteration-count: 1;
  background-color: white;
  border: 1px solid #dee2e6;
  width: 500px;
  padding:10px;
  box-sizing: content-box;
}

#unit-search-bar {
  width:3vw;
  height:2vh;
}

#unit-header {
  padding-bottom: 0;
}

.hidden {
  display:none;
}

.invoice {
  display: flex;
  flex-grow: 1;
  min-height:calc(100vh - 100px);

  .image-section {
    flex:3;
    background: #EEEEEE;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    height: 100vh;
    img {
      max-width: 100%;
      cursor: pointer;
    }

    .tab-title {
      text-transform: uppercase;
      text-align: center;
      color: #F39800;
      cursor: pointer;

      &:hover {
        background: darken(#fffbf7, 10%);
      }
    }
  }

  .left-menu-section {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    animation: fadeIn 0.1s linear both;
    animation-iteration-count: 1;

    & > div {
      position: relative;
      width: 60%;
      background: #EEEEEE;
      height: 100%;
      left:-100%;
      animation: slideIn 0.1s linear both;
      animation-iteration-count: 1;
    }
  }

  .invoice-value {
    color: #2A1812;
  }

  .content-section {
    flex:5;

    .tab-title {
      background: #fffbf7;
      text-transform: uppercase;
      text-align: center;
      color: #F39800;
      cursor: pointer;

      &:hover {
        background: darken(#fffbf7, 10%);
      }
    }

    .log-details {
      background-color: #fffbf7;
    }

    .row-hover:hover {
      background-color: #fffbf7;
      cursor: pointer;
    }

    .selected-log {
      background: darken(#fffbf7, 10%);
    }

    .tab-selected {
      background: darken(#fffbf7, 5%);
    }

    .main-tabs > div {
      border-bottom:1px solid #ddd;
      border-right: 1px solid #ddd;
    }

    .row {
      .col-md-2, .col-md-3 {
        display: flex;
        align-items: center;
      }
    }
  }

  .invoice-items-table {
    text-transform: uppercase;
    table-layout: fixed;

    th {
      background: #F8F6F3;
      opacity: 1;
      white-space: nowrap;
      font-weight: 400;
    }

    tr {
      .item-actions {
        display: none;
        position: absolute;
        top:0;
        left:100%;

        svg {
          width: 20px;
          height:20px;
        }
      }

      &:hover {
        .item-actions {
          display: block;
        }
      }
    }
  }
}

.hidden-actions-container {
  span {
    position: relative;
  }

  .hidden-actions {
    display: none;
    position: absolute;
    top:-3px;
    right:-40px;

    svg {
      width: 20px;
      height:20px;
    }
  }

  &:hover {
    .hidden-actions {
      display: block;
    }
  }
}

.offset-menu {
  margin-left: $menuWidth;
}

.btn-no-border-radius {
  border-radius: 0;
}

.btn-primary-shadow {
  box-shadow: 0px 8px 16px rgba(255, 143, 52, 0.16);
}

.flex-1 { flex: 1}
.flex-2 { flex: 2}
.flex-3 { flex: 3}
.flex-4 { flex: 4}
.flex-5 { flex: 5}

.full-width {
  width: 100%;
}

.faded {
  opacity: 0.3;
}

.semi-faded {
  opacity: 0.6;
}

.search-field {
  max-width:400px;
  font-size:0.85rem;
  background-image: url('/assets/img/icon-search.svg');
  background-position: 10px center;
  padding-left:40px;
  background-repeat: no-repeat;
}

.search-input-field {
  width:140px;
  font-size:0.85rem;
  padding:5px;
}

.btn-xs {
  font-size: 0.7em;
  padding: 0.15rem;
}

.search-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom:10px;

  & > div {
    flex: 1;

    &:nth-child(3) {
      flex:0;
      padding:5px 10px;
      flex-shrink: 1;
    }
    &:last-child {
      display: flex;
      justify-content: flex-end;
      text-align: right;
    }
  }
}

.uploading-container {
  box-shadow: 0px 24px 48px rgba(66, 57, 50, 0.08);
  background-color: white;
  width: 550px;
  min-height: 358px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload-container {
  border: 2px dashed #EBE6E1;
  box-shadow: 0px 24px 48px rgba(66, 57, 50, 0.08);

  & > .new-files-container,
  & > .first-files-container {
    background: rgba(233, 229, 225, 0.32);
    width: 100%;
    min-height: 358px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.menu {
  width: calc(#{$menuWidth} - 50px);
  padding-left : 50px;
  position: fixed;
  min-height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #7B7672;
  z-index: 2;

  ul {
    list-style-position: inside;
    padding-left: 0;

    li {
      margin-bottom:12px;

      a {
        color:black;
      }

      &.selected {
        font-weight: 600;
        color: $primary;

        span {
          color:black;
        }
      }
    }

    &.iconed {
      list-style-type: none;

      li {
        position: relative;
        padding-left:35px;

        &::before {
          position: absolute;
          top:5px;
          left:0;
          background-color: #999;
          height:18px;
          width:18px;
          content: "";
        }

        &.selected {
          &::before {
            background-color: $primary;
          }
        }

        &.dashboard-link::before {
          mask:url('/assets/img/icon-home.svg') no-repeat 0% 0%;
        }

        &.data-link::before {
          mask: url('/assets/img/icon-files.svg') no-repeat 0% 0%;
        }

        &.settings-link::before {
          mask: url('/assets/img/icon-settings.svg') no-repeat 0% 0%;
        }

        &.admin-link::before {
          mask: url('/assets/img/icon-admin.svg') no-repeat 0% 0%;
        }

        &.workflow-link::before {
          mask: url('/assets/img/icon-settings.svg') no-repeat 0% 0%;
        }
        &.payments-link::before {
          mask: url('/assets/img/icon-payment.svg') no-repeat 0% 0%;
        }
      }
    }
  }
}

.new-invoice {
  .invoice-item {
    border:1px solid #EFF0F2;
    width: 100%;
    padding: 20px;
  }

  .new-files-container {
    padding:25px;
    background-color: white;
  }
}

.workflow-container{
  background: linear-gradient(#F3F2F2, #fff);

  h3 {
    text-transform: uppercase;
    font-size: 1.2rem;
    border-bottom:1px solid #aaa;
    margin-top: 20px;
    padding-bottom: 10px;


    button {
      padding:4px 10px;
      line-height: 1em;
    }
  }
}

.workflow-page {
  #workflow-rules {
    width:100%;
    min-height:10vh;
    flex-wrap: wrap;
    background-color: white;
    display: flex;
    padding:20px;
    align-items: center;

    button {
      padding:5px 20px;
      margin:5px;
      width: 100%;
    }
  }

  #workflow-diagram {
    width:100%;
    background-color: black;

    label {
      margin-top:10px;
    }

    h3 {
      text-transform: uppercase;
      font-size: 1.2rem;
      border-bottom:1px solid #aaa;
      margin-top: 20px;
      padding-bottom: 10px;


      button {
        padding:4px 10px;
        line-height: 1em;
      }
    }

    h5 {
      color: #000000;
    }

    form {
      height: 100%;
    }
  }

  #rule-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
}

.line-in-middle {
  background: linear-gradient(
                  to right,
                  transparent 0%,
                  transparent calc(50% - 0.81px), /* Shift the line to 25% */
                  lightgray calc(50% - 0.8px),
                  lightgray calc(50% + 0.8px),
                  transparent calc(50% + 0.81px),
                  transparent 100%
  );

}

.line-in-middle .branch{
  background: #F3F2F2;
  border-top: 2px solid lightgray;

}

.top-border {
  border-top: 2px solid lightgray;
}

.step-connector {
  min-height: 20px;
  max-height: 20px;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  background: linear-gradient(
                  to right,
                  transparent 0%,
                  transparent calc(50% - 0.81px),
                  lightgray calc(50% - 0.8px),
                  lightgray calc(50% + 0.8px),
                  transparent calc(50% + 0.81px),
                  transparent 100%
  );
}

.step-connector.expanded {
  max-height: 100px;
}


.infinite-rotate {
  animation: rotate 3s linear both;
  animation-iteration-count: infinite;
}

@keyframes rotate {
  100%{
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  100%{
    opacity:1;
  }
}

@keyframes slideIn {
  100%{
    left:0;
  }
}

/* Custom class for changing bullet color of password verification */
.password-check-default {
  list-style: none; /* Remove default bullets */
}

.password-check-default:before {
  content: "\2022"; /* Use a custom bullet character (e.g., a black circle) */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1.5em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
  font-weight: bold;
}

.password-check-green {
  list-style: none; /* Remove default bullets */
}

.password-check-green:before {
  content: "\2713\0020"; /* Use a custom bullet character (e.g., a black circle) */
  color: green; /* Set the default bullet color to red */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1.5em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
  font-weight: bold;
}

.settings-conformity-text {
  color: #A19D9A;
  font-style: italic;
}

.comments-column {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
}

.one-line {
  white-space: nowrap;      // Keep text on a single line
  overflow: hidden;         // Hide overflowing content
  text-overflow: ellipsis;  // Display ellipsis for overflow
}

.comments-column:hover {
  background-color: $primary;
  border-radius: 8px;
  padding: 8px;
  position: absolute;
  white-space: wrap;
  color: whitesmoke;
  z-index: 1;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.2s linear;
}

#icon-preview-close {
  position: absolute;
  color: #F39800;
  width: 100%;
  margin: 0;
  padding: 0;
  padding-right: 7%;
  float: right;
  text-align: right;
}

.icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
}

.draggable-icon {
  display: none;
}

th:hover {
  .draggable-icon {
    display: inline;
    cursor: pointer;
    margin: 10px;
    transition: 0.25s all;
  }
}

.report-label {
  padding: 5px;
  margin: 5px 5px 5px 10px;
}

.switch {
  margin: 5px 0 5px 0;
}

.navbar {
  display: flex;
  align-items: stretch;
  padding: 0;
  width: 100%;
  //overflow-x: auto;
  white-space: nowrap;
  //scrollbar-width: none;
}


.nav-tabs {
  display: flex;
  align-items: center;
  border-width: 0;
  flex: 1;
  border-bottom: 1px solid #eaeaea;
  overflow-x: auto;
  flex-wrap: nowrap;
  scrollbar-width: none;
  gap: 0.5rem;

}

.nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s ease-in-out;
  padding: 1%;
}

.nav-item.active {
  border-bottom: 2px solid $primary; /* Active tab indicator */
}

.nav-link {
  color: #000000;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  height: 75%;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.nav-link:hover{
  color: $primary; /* Hover color */
  border-width: 0;
  text-decoration: underline;
}

.active .nav-link {
  color: $primary; /* Hover color */
}

.nav-link span {
  margin-left: 8px;
}

.admin-row{
  .item-actions {
    display: none;
  }
  &:hover {
    .item-actions {
      display: block;
    }
  }
}
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

table {
  width: 100%;
  border-collapse: collapse;
}
.admin-table {
  overflow-y: scroll;
  width: 100%;
  height: 70vh;

  table {
    border-collapse: separate;
    border-spacing: 0;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;

  }
  td {
    border-bottom: 1px solid lightgray;
  }

}

.custom-border-right {
  border-right: 1px solid #eaeaea;
}

.pagination-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  padding: .5rem;
}

.invoice-row-status-completed {
  color: #09AB64;
  td, input{
    background-color: transparentize(lighten(#09AB64, 45%), 0.1) !important;
  }
}

.invoice-row-status-finalized {
  color: #03b1f0;
  td, input {
    background-color: transparentize(lighten(#03b1f0, 45%), 0.1) !important;
  }
}

.invoice-row-status-need_approval {
  color: #F39800;
  td, input {
    background-color: transparentize(lighten(#F39800, 45%), 0.1) !important;
  }
}

.invoice-row-status-denied {
  color: #B81414;
  td, input {
    background-color: transparentize(lighten(#B81414, 45%), 0.1) !important;
  }
}

.invoice-row-status-approved {
  color: #369654;
  td, input {
    background-color: transparentize(lighten(#369654, 45%), 0.1) !important;
  }
}

.invoice-row-status-need_attention{
  color: #EE3737;
  td, input {
    background-color: transparentize(lighten(#EE3737, 45%), 0.1) !important;
  }}

.invoice-row-status-pending {
  color: #F39800;
  td, input {
    background-color: transparentize(lighten(#F39800, 45%), 0.1) !important;
  }
}

.invoice-row-status-invalid{
  color: #EE3737;
  td, input {
    background-color: transparentize(lighten(#EE3737, 45%), 0.1) !important;
  }
}

.invoice-row-status-processing{
  color: #F39800;
  td, input {
    background-color: transparentize(lighten(#F39800, 45%), 0.1) !important;
  }
}

.day-btn {
  margin-right: 6px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid #ccc;
  background-color: transparent;
  color: #000;
  cursor: pointer;
  text-align: center;
  line-height: 28px;
}

.day-btn-selected {
  border: 2px solid $primary;
  background-color: $primary;
  color: #fff;
}

th, td{
  vertical-align: middle !important;
}

.border-highlight-secondary {
  box-shadow: 0px 0px 5px $secondary;
}

.border-highlight {
  box-shadow: 0px 0px 5px $primary;
}
